import { template as template_4e885a4e15764344aacdf5bb9b304abc } from "@ember/template-compiler";
const FKText = template_4e885a4e15764344aacdf5bb9b304abc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
