import { template as template_a75d0254479044a7851a4f070878b214 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a75d0254479044a7851a4f070878b214(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
