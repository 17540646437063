import { template as template_da56db2d98ec42348614809c901c07e3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_da56db2d98ec42348614809c901c07e3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
