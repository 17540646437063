import { template as template_46a085cbcc7848d69b971f959f9294f8 } from "@ember/template-compiler";
const FKLabel = template_46a085cbcc7848d69b971f959f9294f8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
